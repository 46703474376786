import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import {
  Home,
  Login,
  Orders,
  Products,
  Reviews,
  Settings,
  Categories,
  Project,
  Blog,
  Sound,
  Member,
  Company,
  Users,
  About,
  Contact,
  Slide,
  AllDonate,
  AllOrder,
  DonateDetail,
  OrderDetail,
  Allalbum,
  Albumdetail,
  BankAccount,
  Youtube,
  ProjectVedio,
  Sponsor,
  Menus,
  SubProject,
  AboutCompany,
  SubMenus,
} from "./pages";

import Layout from "./components/Layout";
import { AddCat, View, Update } from "./components/categories";
import { AddCompany, ViewCompany } from "./components/company";
import { AddProject, ViewProject, SelectSound } from "./components/project";
import { AddProduct, ViewProduct } from "./components/product";
import { AddBlog, ViewBlog } from "./components/blog";
import { AddSound, ViewSound } from "./components/sound";
import { AddUser, ViewUser } from "./components/users";
import { AddAbout, ViewAbout } from "./components/abount";
import { AddContact, ViewContact } from "./components/contact";
import { AddSlide, ViewSlide } from "./components/slide";
import { AddBankAccount, ViewBankAccount } from "./components/bankaccount";

import { AddSponsor, ViewSponsor } from "./components/sponsor";
import { AddInfoslide, ViewInfoslide } from "./components/infoslide";
import { AddAbountCompany, ViewAbountCompany } from "./components/aboutcompany";
import ViewMenu from "./components/menu/ViewMenu";
import ViewSubMenu from "./components/menu/ViewSubMenu";

import { Infoslide } from "./pages";

const MainLayout = () => {
  return (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/allorders",
        element: <AllOrder />,
      },
      {
        path: "/orderdetail/:id",
        element: <OrderDetail />,
      },
      {
        path: "/donates",
        element: <AllDonate />,
      },
      {
        path: "/youtubedetail/:id",
        element: <Youtube />,
      },
      {
        path: "/projectvedio/:id",
        element: <ProjectVedio />,
      },
      {
        path: "/subproject/:id",
        element: <SubProject />,
      },
      {
        path: "/donatedetail/:id",
        element: <DonateDetail />,
      },
      {
        path: "/allalbums",
        element: <Allalbum />,
      },
      {
        path: "/albumdetail/:id",
        element: <Albumdetail />,
      },
      {
        path: "/products",
        element: <Products />,
        children: [
          {
            path: "viewproduct",
            element: <ViewProduct />,
          },
          {
            path: "addproduct",
            element: <AddProduct />,
          },
        ],
      },
      {
        path: "/aboutcompany",
        element: <AboutCompany />,
        children: [
          {
            path: "viewaboutcompany",
            element: <ViewAbountCompany />,
          },
          {
            path: "addaboutcompany",
            element: <AddAbountCompany />,
          },
        ],
      },
      {
        path: "/sponsors",
        element: <Sponsor />,
        children: [
          {
            path: "viewsponsor",
            element: <ViewSponsor />,
          },
          {
            path: "addsponsor",
            element: <AddSponsor />,
          },
        ],
      },
      {
        path: "/infoslides",
        element: <Infoslide />,
        children: [
          {
            path: "viewinfoslide",
            element: <ViewInfoslide />,
          },
          {
            path: "addinfoslide",
            element: <AddInfoslide />,
          },
        ],
      },
      {
        path: "/menus",
        element: <Menus />,
        children: [
          {
            path: "viewmenu",
            element: <ViewMenu />,
          },
        ],
      },
      {
        path: "/submenus",
        element: <SubMenus />,
        children: [
          {
            path: "viewsubmenu",
            element: <ViewSubMenu />,
          },
        ],
      },
      {
        path: "/reviews",
        element: <Reviews />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/categories",
        element: <Categories />,
        children: [
          {
            path: "viewcat",
            element: <View />,
          },
          {
            path: "addcat",
            element: <AddCat />,
          },
        ],
      },
      {
        path: "/bankaccount",
        element: <BankAccount />,
        children: [
          {
            path: "viewbank",
            element: <ViewBankAccount />,
          },
          {
            path: "addbank",
            element: <AddBankAccount />,
          },
        ],
      },
      {
        path: "/blogs",
        element: <Blog />,
        children: [
          {
            path: "viewblog",
            element: <ViewBlog />,
          },
          {
            path: "addblog",
            element: <AddBlog />,
          },
        ],
      },
      {
        path: "/abouts",
        element: <About />,
        children: [
          {
            path: "viewabout",
            element: <ViewAbout />,
          },
          {
            path: "addabout",
            element: <AddAbout />,
          },
        ],
      },
      {
        path: "/contacts",
        element: <Contact />,
        children: [
          {
            path: "viewcontact",
            element: <ViewContact />,
          },
          {
            path: "addcontact",
            element: <AddContact />,
          },
        ],
      },
      {
        path: "/slides",
        element: <Slide />,
        children: [
          {
            path: "viewslide",
            element: <ViewSlide />,
          },
          {
            path: "addslide",
            element: <AddSlide />,
          },
        ],
      },
      {
        path: "/sounds",
        element: <Sound />,
        children: [
          {
            path: "viewsound",
            element: <ViewSound />,
          },
          {
            path: "addsound",
            element: <AddSound />,
          },
        ],
      },
      {
        path: "/projects",
        element: <Project />,
        children: [
          {
            path: "viewproject",
            element: <ViewProject />,
          },
          {
            path: "addproject",
            element: <AddProject />,
          },
          {
            path: ":id",
            element: <SelectSound />,
          },
        ],
      },
      {
        path: "/members",
        element: <Member />,
      },
      {
        path: "/users",
        element: <Users />,
        children: [
          {
            path: "viewuser",
            element: <ViewUser />,
          },
          {
            path: "adduser",
            element: <AddUser />,
          },
        ],
      },
      {
        path: "/companys",
        element: <Company />,
        children: [
          {
            path: "viewcompany",
            element: <ViewCompany />,
          },
          {
            path: "addcompany",
            element: <AddCompany />,
          },
        ],
      },
      /*       {
        path: "/account",
        element: <Account />,
        children: [
          {
            path: "gfaccount",
            element: <GfAccount />,
          },
          {
            path: "pnaccount",
            element: <PnAccount />,
          },
          {
            path: "proviceapp",
            element: <Provice />,
          },
          {
            path: "advertapp",
            element: <AdvertApp />,
          },
        ],
      }, */
      /*       {
        path: "/income",
        element: <Income />,
        children: [
          {
            path: "gfincome",
            element: <GfIncome />,
          },
          {
            path: "pnincome",
            element: <PnIncome />,
          },
          {
            path: "dsincome",
            element: <DisIncome />,
          },
          {
            path: "bankname",
            element: <BankName />,
          },
        ],
      }, */
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
