import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { bankUrl } from "../utils/baseUrl";
import { orderUrl, statusUrl } from "../utils/baseUrl";
import CustomModal from "../components/CustomModal";
import axios from "axios";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";

//export const orderUrl = `${url}orders/`;
//
const AllOrder = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idEdit, setIdEdit] = useState("");
  const [statusId, setStatusId] = useState("");
  const [trackNo, setTrackNo] = useState("");

  function readorder() {
    axios.get(`${orderUrl}readorders.php`).then(function (response) {
      console.log(response.data);
      setOrders(response.data);
    });
  }
  function readostatus() {
    axios.get(`${statusUrl}read.php`).then(function (response) {
      console.log(response.data);
      setStatus(response.data);
    });
  }
  useEffect(() => {
    readorder();
    readostatus();
  }, []);
  const addStatus = (item) => {
    setIdEdit(item?.id);
    setStatusId(item?.isstatus);
    setTrackNo(item?.trackno);
    setShowModal(!false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(statusId);
    /*     if (statusId == "0" || statusId == "") {
      alert("กรุณากำนดสถานะหรือเลขพ้สดุ");
      setShowModal(true);
      return;
    } */
    // alert(statusId);
    const formData = new FormData();
    formData.append("isstatus", statusId);
    formData.append("trackno", trackNo);
    formData.append("id", idEdit);
    axios
      .post(`${orderUrl}updatestatus.php`, formData)
      .then(function (response) {
        console.log(response.data);
        readorder();
        setShowModal(false);
      });
    // event.preventDefault();
    /*     console.log(selectedFile);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("categoryId", categoryId);
    formData.append("projectName", projectName);
    formData.append("location", location);
    formData.append("detail", detail);
    formData.append("beginDate", beginDate);
    formData.append("endDate", endDate);
    formData.append("total", total);
    formData.append("id", idEdit);
    axios.post(`${pjUrl}update.php`, formData).then(function (response) {
      console.log(response.data);
      readpj();
      notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
      setShowModal(false);
    }); */
  };
  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">
        รายการใบสั่งซื้อสินค้า
      </h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  <strong>ชื่อสกุล</strong> {item.id}#{item.firstname}{" "}
                  {item.lastname}
                  <br />
                  <strong>โทรศัพท์</strong> {item.phone}
                  <br />
                  <strong>อีเมล์</strong> {item.email}
                </div>
                <div>
                  <h3>ที่อยู่จัดส่งสินค้า</h3>
                  <strong>ที่อยู่</strong> {item.address}
                  <br />
                  <strong>จังหวัด</strong> {item.city}
                  <br />
                  <strong>รหัสไปรษณีย์</strong> {item.postcode}
                </div>
                <div>
                  <strong>จำนวน</strong> {item.itemAmount}{" "}
                  <strong>หน่วย</strong>
                </div>
                <div>
                  <strong>ยอดสุทธิ</strong> {item.total} บาท
                </div>
                <div>{item.trackno != "" ? item.trackno : "-"}</div>
                <div>{item.isstatus != "" ? item.isstatus : "-"}</div>
                <div className="flex flex-col justify-end gap-1 md:flex-row">
                  <button
                    onClick={() => addStatus(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    กำหนดสถานะ/เลขพัสดุ
                  </button>
                  <Link
                    to={`/orderdetail/${item.id}`}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    รายการสินค้า
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* --------Sound Modal-------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              กำหนดสถานะ/เลขพัสดุ
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="mb-2">
              <select
                value={statusId}
                onChange={(e) => setStatusId(e.target.value)}
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
              >
                <option value=""></option>
                {status.map((item, index) => (
                  <option key={index} value={`${item.thName}(${item.enName})`}>
                    {item.thName} {item.enName}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
                placeholder="เลขพัสดุ"
                name="trackNo"
                value={trackNo}
                onChange={(e) => setTrackNo(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/* -------------  */}
    </>
  );
};

export default AllOrder;
