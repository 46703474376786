import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { videoUrl, videoFileUrl } from "../utils/baseUrl";

import axios from "axios";
import CustomModal from "../components/CustomModal";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";

import { Player } from "video-react";
import "video-react/dist/video-react.css"; // import css

const ProjectVedio = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thName: "",
    },
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [datas, setDatas] = useState([]);

  function readData(pjid) {
    axios.get(`${videoUrl}readvideo.php/${pjid}`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
    });
  }

  useEffect(() => {
    readData(id);
  }, []);

  /*   const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${videoUrl}add.php`, {
        projectId: id,
        userId: userId,
        title,
        url,
      })
      .then(function (response) {
        console.log(response.data);
        readYoutube(id);
        setTitle("");
        setUrl("");
        notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        setShowModal(false);
      });
  }; */

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    formData.append("thName", data.thName);
    formData.append("projectId", id);
    setLoading(true);

    // console.log(data.thName, data.enName);

    axios.post(`${videoUrl}addvideo.php`, formData).then(function (response) {
      console.log(response.data);
      readData(id);
      reset();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };

  const deleteData = (vid) => {
    axios
      .delete(`${videoUrl}deletevideo.php/${vid}/delete`)
      .then(function (response) {
        readData(id);
        notifyError("ลบข้อมูลเรียบร้อยแล้ว");
      }); //max-w-[1640px]
  };
  const addLinkYoutube = () => {
    // setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowModal(!false);
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">
        ความคืบหน้าโครงการ {id}
      </h1>
      <table>
        <thead>
          <tr>
            {/* <th>รูปภาพ</th> */}
            <th>
              <button
                onClick={() => addLinkYoutube()}
                className="bg-blue-500 text-sm hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
              >
                เพิ่มความคืบหน้าโครงการ
              </button>
            </th>
            {/* <th>ที่ตั้งโครงการ</th> */}
            {/* <th>เริ่ม/สิ้นสุดโครงการ งบโครงการ</th> */}
            {/* <th>งบโครงการ</th> */}
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {datas?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item?.video !== "" ? (
                    <>
                      <Player>
                        <source src={`${videoFileUrl}${item?.video}`} />
                      </Player>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="mb-4">
                  {/*                   <h3>
                  {" "}
                  {item.id}.{item.title}
                </h3> */}
                  {/*              <p
                    className="mb-4 text-sm"
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                  ></p> */}
                  <br />
                  <strong>
                    {" "}
                    {item?.id}# {item?.thName}
                  </strong>
                  <br />
                  {/*                 <strong>เริ่ม</strong> {item.beginDate}
              <br />
              <strong>สิ้นสุดโครงการ</strong> {item.endDate}
              <br />
              <strong>งบโครงการ</strong>{" "}
              {item.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท */}
                </div>
                {/* pjyoutube id,userId,projectId,title,url */}
                <div className="flex justify-end gap-1 flex-1">
                  <button
                    onClick={() => deleteData(item?.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* -------------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              เพิ่มความคืบหน้าโครงการ
            </h1>
          </div>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 my-2 ml-1">
                รายละเอียด
              </label>
              <input
                type="text"
                className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="รายละเอียด"
                name="thName"
                {...register("thName", {
                  required: true,
                  // maxLength: 10,
                })}
              />
              {errors.thName && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนรายละเอียด
                </div>
              )}
            </div>
            <div className="my-2">
              <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
                ไฟล์วีดิโอ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="min-w-[120px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 border border-blue-700 rounded"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default ProjectVedio;
