import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { pjUrl, imgPjUrl } from "../../utils/baseUrl";
import { catUrl } from "../../utils/baseUrl";
import { soundUrl, soundFileUrl } from "../../utils/baseUrl";
import axios from "axios";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};
const ViewProject = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [catData, setCatData] = useState([]);
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showSoundModal, setShowSoundModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [showPosterModal, setShowPosterModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectedFile, setSectedFile] = useState(null);
  const [projects, setProjects] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [location, setLocation] = useState("");
  const [detail, setDetail] = useState("");
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [total, setTotal] = useState("");
  const [soundId, setSoundId] = useState("");
  const [soundData, setSoundData] = useState([]);
  const [posterData, setPosterData] = useState([]);
  const [soundname, setSoundname] = useState("");

  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    console.log("My user ID: ", id);
    setUserId(id);
  }, []);
  //formData.append("userId", userId);
  //กำลังดำเนินการลบข้อมูล
  //กำลังดำเนินการบันทึกข้อมูล
  //$userId = $_POST["userId"];

  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }
  function readpj() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }
  function readSound() {
    axios.get(`${soundUrl}read.php`).then(function (response) {
      console.log(response.data);
      setSoundData(response.data);
    });
  }
  /*   function readPoster(pjid) {
    axios.get(`${pjUrl}readposter.php/${pjid}`).then(function (response) {
      console.log(response.data);
      setPosterData(response.data);
    });
  } */
  useEffect(() => {
    readcat();
    readSound();
    readpj();
  }, []);

  // console.log(soundData);
  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const deleteIncome = (id) => {
    axios.delete(`${pjUrl}delete.php/${id}/delete`).then(function (response) {
      readpj();
      notifyError("กำลังดำเนินการลบข้อมูล");
    }); //max-w-[1640px]
  };
  const editData = (item) => {
    setIdEdit(item?.id);
    setCategoryId(item?.categoryId);
    setProjectName(item?.projectName);
    setLocation(item?.location);
    setDetail(item?.detail);
    setBeginDate(item?.beginDate);
    setEndDate(item?.endDate);
    setTotal(item?.total);
    setShowModal(!false);
  };
  const addSoundData = (item) => {
    setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowSoundModal(!false);
  };
  const addGalleryData = (item) => {
    setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowGalleryModal(!false);
  };
  const addPosterData = (item) => {
    setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowPosterModal(!false);
  };
  /*   const addSound = (item) => {
    navigate(`projects`, { replace: true, state: { id: item.id } });
    navigate(`/soundfile/${item.id}`,{});
    navigate("/books", { replace: true, state: { bookName: "Fake Title" }})
  }; */

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(selectedFile);
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("categoryId", categoryId);
    formData.append("projectName", projectName);
    formData.append("location", location);
    formData.append("detail", detail);
    formData.append("beginDate", beginDate);
    formData.append("endDate", endDate);
    formData.append("total", total);
    formData.append("id", idEdit);
    setLoading(true);

    axios.post(`${pjUrl}update.php`, formData).then(function (response) {
      console.log(response.data);

      readcat();
      readSound();
      readpj();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
      setShowModal(false);
    });
  };
  const handleSoundSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("soundId", soundId);
    formData.append("id", idEdit);
    formData.append("userId", userId);
    axios.post(`${pjUrl}updatesound.php`, formData).then(function (response) {
      console.log(response.data);
      readcat();
      readSound();
      readpj();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setShowSoundModal(false);
    });
  };
  const handleGallerySubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("id", idEdit);
    formData.append("userId", userId);
    axios.post(`${pjUrl}addgallery.php`, formData).then(function (response) {
      console.log(response.data);
      readcat();
      readSound();
      readpj();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setShowGalleryModal(false);
    });
  };
  const handlePosterSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("id", idEdit);
    axios.post(`${pjUrl}updateposter.php`, formData).then(function (response) {
      console.log(response.data);

      readpj();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setShowGalleryModal(false);
    });
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <div>
      <table>
        <thead>
          <tr>
            {/* <th>รูปภาพ</th> */}
            <td>ชื่อโครงการ</td>
            {/* <th>ที่ตั้งโครงการ</th> */}
            {/* <th>เริ่ม/สิ้นสุดโครงการ งบโครงการ</th> */}
            {/* <th>งบโครงการ</th> */}
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {projects?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.picture !== "" ? (
                    <img
                      src={`${imgPjUrl}${item.picture}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                  {item?.posterFile !== null ? (
                    <img
                      src={`${imgPjUrl}${item.posterFile}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                  <br />
                  {item.soundFile !== "" ? (
                    <>
                      <ReactAudioPlayer
                        src={`${soundFileUrl}${item.soundFile}`}
                        className="w-full md:w-[300px]"
                        // autoPlay
                        controls
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="my-4">
                  <h3>{item.projectName}</h3>
                  <br />
                  <strong>ที่ตั้งโครงการ</strong> {item.location}
                  <br />
                  <strong>เริ่ม</strong> {item.beginDate}
                  <br />
                  <strong>สิ้นสุดโครงการ</strong> {item.endDate}
                  <br />
                  <strong>งบโครงการ</strong>{" "}
                  {item.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท
                </div>
                {/*                 <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: item.productDetail }}
                ></p> */}
                <div className="flex flex-col justify-end gap-1 flex-1 mt-2 md:flex-row">
                  {/*                   <Link
                    to={`${item.id}`}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    กำหนดไฟล์เสียง
                  </Link> */}
                  <button
                    onClick={() => addPosterData(item)}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    กำหนดใบอนุโม
                  </button>
                  <button
                    onClick={() => addGalleryData(item)}
                    className="bg-indigo-500 text-sm  hover:bg-indigo-700 text-white text-center py-1 px-4 rounded"
                  >
                    อัลบั้มรูป
                  </button>
                  <button
                    onClick={() => addSoundData(item)}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    กำหนดไฟล์เสียง
                  </button>
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                  <button
                    onClick={() => deleteIncome(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* --------Sound Modal-------  */}
      <CustomModal visible={showSoundModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              กำหนดเสียงขอบคุณสำหรับโครงการ
            </h1>
          </div>
          <form onSubmit={handleSoundSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="mb-2">
              <select
                value={soundId}
                onChange={(e) => setSoundId(e.target.value)}
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {soundData.map((item, index) => (
                  <option key={index} value={item.file}>
                    {item.soundname}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowSoundModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/* -------------  */}
      {/* --------Gallery Modal-------  */}
      <CustomModal visible={showGalleryModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              สร้างอัลบั้มรูปโครงการ
            </h1>
          </div>
          <form onSubmit={handleGallerySubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="my-3">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowGalleryModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/* --------Poster Modal-------  */}
      <CustomModal visible={showPosterModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              กำหนดใบอนุโม
            </h1>
          </div>
          <form onSubmit={handlePosterSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="my-3">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowPosterModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/* -------------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              แก้ไขโครงการ
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-2">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
              <div className="mb-2">
                <select
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                >
                  <option value=""></option>
                  {catData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อโครงการ"
                name="projectName"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="รายละเอียดที่ตั้งโครงการ"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="mb-12 h-[100px]">
              <ReactQuill
                modules={modules}
                theme="snow"
                className="h-5/6"
                value={detail}
                onChange={setDetail}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="mb-2">
                <input
                  type="date"
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder={`วันเริ่มโครงการ ${beginDate}`}
                  name="beginDate"
                  // value={beginDate}
                  defaultValue={beginDate}
                  onChange={(e) => setBeginDate(e.target.value)}
                />
                {/* {`วันเริ่มโครงการ ${beginDate}`} */}
              </div>

              <div className="mb-2">
                <input
                  type="date"
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder={`วันสิ้นสุดโครงการ ${endDate}`}
                  name="endDate"
                  // value={endDate}
                  defaultValue={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                {/* {`วันสิ้นสุดโครงการ ${endDate}`} */}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-2">
                <input
                  type="text"
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder="จำนวนงบโครงการ"
                  name="total"
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                {loading ? <>กรุณารอ..</> : <>บันทึก99</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
};

export default ViewProject;
