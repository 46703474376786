import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { catUrl } from "../../utils/baseUrl";

import axios from "axios";

//cateogries(categoryName)
const AddCat = () => {
  const [categoryName, setCategoryName] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    console.log("My user ID: ", id);
    setUserId(id);
  }, []);
  //formData.append("userId", userId);
  //กำลังดำเนินการลบข้อมูล
  //กำลังดำเนินการบันทึกข้อมูล
  //$userId = $_POST["userId"];
  const onSubmitHandler = (event) => {
    event.preventDefault();
    // alert("");
    axios
      .post(`${catUrl}add.php`, {
        categoryName: categoryName,
        userId: userId,
      })
      .then(function (response) {
        console.log(response.data);
        setCategoryName("");
        notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      });
    //Form submission happens here
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <div className="">
      <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <form onSubmit={onSubmitHandler}>
          <div className="mb-6">
            <input
              type="text"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="ชื่อประเภทโครงการ/สินค้า"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-3 text-center bg-blue-500 border rounded-lg text-white  hover:bg-blue-600 active:bg-blue-700 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddCat;
