import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";
// import { bankUrl } from "../utils/baseUrl";
import { donateUrl } from "../utils/baseUrl";
import { pjUrl, pjgalleryUrl, imgPjUrl } from "../utils/baseUrl";
import axios from "axios";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";

const DonateDetail = () => {
  //   const navigate = useNavigate();

  const { id } = useParams();
  const [donates, setDonate] = useState([]);
  const [projects, setProjects] = useState([]);
  const [gallerys, setGallerys] = useState([]);
  let amount = 0;
  let subtotal = 0;
  let total = 0;
  function readpj() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }
  function readdonate() {
    axios.get(`${donateUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDonate(response.data);
    });
  }
  function readgallery() {
    axios.get(`${pjgalleryUrl}read.php`).then(function (response) {
      console.log(response.data);
      setGallerys(response.data);
    });
  }
  useEffect(() => {
    readdonate();
    readpj();
    readgallery();
  }, []);
  const project = projects?.find((item) => {
    return item.id == id; // return item.id === parseInt(id);
  });
  const donate = donates?.filter((item) => {
    return item.projectId == id; // return item.id === parseInt(id);
  });
  const gallery = gallerys?.filter((item) => {
    return item.projectId == id; // return item.id === parseInt(id);
  });

  const totalDonate = donate.reduce((accumulator, currentItem) => {
    return Number(accumulator) + Number(currentItem.total);
  }, 0);

  const deleteGallery = (id) => {
    axios
      .delete(`${pjgalleryUrl}delete.php/${id}/delete`)
      .then(function (response) {
        readgallery();
        // notifyError("ลบข้อมูลเรียบร้อยแล้ว");
      }); //max-w-[1640px]
  };

  return (
    <div className="container mx-auto">
      <div className="flex flex-col lg:flex-row h-full py-12">
        {/* left  */}
        <div className="w-full h-full lg:w-[50%] px-6">
          <h2 className=" text-base md:font-bold md:text-xl">
            {project?.projectName}
          </h2>
          <div className="py-4">
            <img
              className="h-[150px] object-cover w-full my-4"
              src={`${imgPjUrl}${project?.picture}`}
              alt=""
            />
            <p
              className="my-8"
              dangerouslySetInnerHTML={{ __html: project?.detail }}
            ></p>
            <div className="text-sm mb-6">
              <strong>ที่ตั้งโครงการ</strong> {project?.location}
              <br />
              <strong>เริ่ม</strong> {project?.beginDate}
              <br />
              <strong>สิ้นสุดโครงการ</strong> {project?.endDate}
              <br />
              <strong>งบโครงการ</strong>{" "}
              {project?.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท
            </div>
            {/* -------  */}
            <div className="flex flex-col gap-5 md:flex-row">
              <div className="bg-blue-200 grow w-full flex items-center gap-2 text-primary p-5 rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                  />
                </svg>
                <div>
                  <h2 className="font-bold text-2xl leading-4">
                    {/* {Number(project?.total).toFixed(2)} บาท */}
                    {project?.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท
                  </h2>
                  <h3 className="text-xs">งบโครงการ</h3>
                </div>
              </div>

              <div className="bg-red-200  grow w-full  flex items-center gap-2 text-primary p-5 rounded-tr-3xl rounded-bl-3xl rounded-tl-md rounded-br-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <div>
                  <h2 className="font-bold text-2xl leading-4">
                    {Number(totalDonate).toFixed(2)} บาท
                    {/* {totalDonate.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท */}
                    {/* {totalDonate.toLocaleString(2)} */}
                  </h2>
                  <h3 className="text-xs">ยอดรวมบริจาค</h3>
                </div>
              </div>
            </div>

            {/* -------  */}
          </div>
          {/* ----------  */}
          <div className="mb-6">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {gallery.map((item, index) => (
                <div className="flex flex-col" key={index}>
                  <img
                    className="object-cover h-[150px] w-full"
                    src={`${imgPjUrl}${item.img}`}
                    alt=""
                  />
                  <button
                    onClick={() => deleteGallery(item.id)}
                    className="bg-red-500 my-2 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              ))}
            </div>
          </div>
          {/* ----------  */}
        </div>
        {/* left  */}
        <div className="w-full h-full lg:w-[50%] px-6">
          <h2 className="text-primary font-bold">รายชื่อผู้บริจาค</h2>
          <div>
            <table>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <td>ข้อมูลผู้บริจาค</td>
                  {/*      <th></th>
                  <th></th>
                  <th></th>
                  <td>Action</td> */}
                </tr>
              </thead>
              <tbody>
                {donate.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div>
                          <strong>ชื่อ-สกุล</strong> {item.id} {item.fullname}
                          <br />
                          <strong>โทรศัพท์</strong> {item.phone}
                          <br />
                          <strong>จำนวนเงิน</strong> {item.total} บาท
                          <br />
                          <strong>สถานะ</strong> {item.ispaid}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* ---------- */}
        </div>
      </div>
    </div>
  );
};

export default DonateDetail;
