import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { proUrl, imgProUrl } from "../../utils/baseUrl";
import { catUrl } from "../../utils/baseUrl";

import axios from "axios";
import CustomModal from "../CustomModal";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};
const ViewProduct = () => {
  const [loading, setLoading] = useState(false);
  const [catData, setCatData] = useState([]);
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [projects, setProjects] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [productName, setProductName] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [price, setPrice] = useState("");
  /*   formData.append("productName", data.productName);
  formData.append("productDetail", data.productDetail);
  formData.append("price", data.price); */
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    console.log("My user ID: ", id);
    setUserId(id);
  }, []);
  //formData.append("userId", userId);
  //กำลังดำเนินการลบข้อมูล
  //กำลังดำเนินการบันทึกข้อมูล
  //$userId = $_POST["userId"];

  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }
  function readpj() {
    axios.get(`${proUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }

  useEffect(() => {
    readcat();
    readpj();
  }, []);

  const handleOnChange = (e) => {
    /*     console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------"); */
    setSectedFile(e.target.files[0]);
  };

  const deleteIncome = (id) => {
    axios.delete(`${proUrl}delete.php/${id}/delete`).then(function (response) {
      readpj();
      notifyError("กำลังดำเนินการลบข้อมูล");
    }); //max-w-[1640px]
  };
  const editData = (item) => {
    setIdEdit(item?.id);
    setCategoryId(item?.categoryId);
    setProductName(item?.productName);
    setProductDetail(item?.productDetail);
    setPrice(item?.price);
    setShowModal(!false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("categoryId", categoryId);
    formData.append("productName", productName);
    formData.append("productDetail", productDetail);
    formData.append("price", price);
    formData.append("id", idEdit);
    setLoading(true);

    axios.post(`${proUrl}update.php`, formData).then(function (response) {
      console.log(response.data);

      /*       setProductName("");
      setProductDetail("");
      setPrice("");
      setCategoryId("");
      setIdEdit("");
      selectedFile(null); */
      readcat();
      readpj();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
      setShowModal(false);
    });
  };
  const addGalleryData = (item) => {
    setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowGalleryModal(!false);
  };

  const handleGallerySubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("id", idEdit);
    formData.append("userId", userId);
    axios.post(`${proUrl}addgallery.php`, formData).then(function (response) {
      // console.log(response.data);
      readcat();
      readpj();
      // setSectedFile(null);
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setShowGalleryModal(false);
    });
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>ชื่อสินค้า/รายละเอียดสินค้า</th>
          </tr>
        </thead>
        <tbody>
          {projects?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.picture !== "" ? (
                    <img
                      src={`${imgProUrl}${item.picture}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <h3>
                  {item.id}#{item.productName}
                </h3>
                <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: item.productDetail }}
                ></p>
                <p>ราคา: {item.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <div className="flex justify-end gap-1 mt-2">
                  <button
                    onClick={() => addGalleryData(item)}
                    className="bg-indigo-500 text-sm  hover:bg-indigo-700 text-white text-center py-1 px-4 rounded"
                  >
                    อัลบั้มรูป
                  </button>
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                  <button
                    onClick={() => deleteIncome(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* --------Gallery Modal-------  */}
      <CustomModal visible={showGalleryModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              สร้างอัลบั้มรูปโครงการ
            </h1>
          </div>
          <form onSubmit={handleGallerySubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 undefined">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
            </div>
            <div className="my-3">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>

            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowGalleryModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      {/* -------------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-2">
                <input
                  type="text"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                />
              </div>
              <div className="mb-2">
                <select
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                >
                  <option value=""></option>
                  {catData.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อโครงการ"
                name="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className="mb-12 h-[100px]">
              <ReactQuill
                modules={modules}
                theme="snow"
                className="h-4/5"
                value={productDetail}
                onChange={setProductDetail}
              />
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ราคาสินค้า"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>

            <div className="my-3">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                {loading ? <>กรุณารอ..</> : <>บันทึก99</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default ViewProduct;
