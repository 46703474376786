import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { catUrl } from "../../utils/baseUrl";

import axios from "axios";
import CustomModal from "../CustomModal";

const View = () => {
  const [catData, setCatData] = useState([]);
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [idEdit, setIdEdit] = useState("");
  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }

  useEffect(() => {
    readcat();
  }, []);

  const deleteIncome = (id) => {
    axios.delete(`${catUrl}delete.php/${id}/delete`).then(function (response) {
      readcat();
      notifyError("ลบข้อมูลเรียบร้อยแล้ว");
    }); //max-w-[1640px]
  };
  const editData = (item) => {
    setIdEdit(item?.id);
    setCategoryName(item?.categoryName);
    setShowModal(!false);
  };
  const handleEditSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`${catUrl}update.php`, {
        id: idEdit,
        categoryName,
      })
      .then(function (response) {
        console.log(response.data);
        readcat();
        // navigate("/");
      });
    notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
    setIdEdit("");
    setCategoryName("");
    setShowModal(false);
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      {/* <h1 className="text-primary text-xl font-bold mb-4">Orders</h1> */}
      <table>
        <thead>
          <tr>
            <th>ประเภทโครงการ/สินค้า</th>
          </tr>
        </thead>
        <tbody>
          {catData.map((item, index) => (
            <tr key={index}>
              <td>
                <h3 className="mb-4">
                  {item.id}#{item.categoryName}
                </h3>
                <div className="flex justify-end gap-1">
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                  <button
                    onClick={() => deleteIncome(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleEditSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700 undefined">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  // name="idEdit"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
                />
              </div>
            </div>
            {/* const { idEdit, incomeEName, amountEPercent, incomeEAmount } = inputEdit; */}
            <div>
              <label className="block mt-3 mb-2 text-sm font-medium text-gray-700 undefined">
                ชื่อประเภทโครงการ/สินค้า
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  placeholder="ชื่อประเภทโครงการ/สินค้า"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default View;
