// const url = "https://dashboardgiftlikes.com/donate_api/";
// const url = "https://xn--72cai1bqam3b7bcbd6c3di2b3and2a5x1gmc.com/donate_api/";
// const url = "https://jdtextileandconsolidator.com/donate_api/";
const url = "https://พระอินทร์ทรงช้างเอราวัณ.com/donate_api/";
// const url = "https://jdtextileandconsolidator.me/donate_api/";
//demo.jdtextileandconsolidator.me
//jdtextileandconsolidator.me
// const url = "http://localhost/donate_api/";
//https://donate.dashboardgiftlikes.com/login
//https://donate.dashboardgiftlikes.com/login
//USE``;
//กำลังดำเนินการบันทึกข้อมูล;
// const url = "http://192.168.1.132:8080/donate_api/";
export const catUrl = `${url}category/`;
export const companyUrl = `${url}company/`;
export const donateUrl = `${url}donate/`;
export const memberUrl = `${url}member/`;
export const userUrl = `${url}users/`;
export const pjyoutubeUrl = `${url}pjyoutube/`;
export const bankUrl = `${url}bank/`;
export const pjUrl = `${url}project/`;
export const subpjUrl = `${url}subproject/`;
export const sponsorUrl = `${url}sponsor/`;
export const infoslideUrl = `${url}infoslide/`;
export const orderUrl = `${url}orders/`;
export const proUrl = `${url}products/`;
export const blogUrl = `${url}blog/`;
export const soundUrl = `${url}sound/`;
export const videoUrl = `${url}video/`;
export const menuUrl = `${url}menu/`;
export const pgalleryUrl = `${url}pgallery/`;
export const pjgalleryUrl = `${url}pjgallery/`;
export const aboutUrl = `${url}about/`;
export const aboutCompanyUrl = `${url}aboutcompany/`;
export const contactUrl = `${url}contact/`;
export const slideUrl = `${url}slider/`;
export const statusUrl = `${url}status/`;
//$target_dir = "../assets/imgs/";
export const imgCompanyUrl = `${url}assets/imgs/`;
export const imgPjUrl = `${url}assets/pjphoto/`;
export const imgProUrl = `${url}assets/pphoto/`;
export const imgBlogUrl = `${url}assets/blog/`;
export const soundFileUrl = `${url}assets/sound/`;
export const videoFileUrl = `${url}assets/video/`; //assets\video
export const sponsorFileUrl = `${url}assets/sponsor/`; //assets\video
export const infoslideFileUrl = `${url}assets/infoslide/`; //assets\video
export const imgUrl = `${url}assets/imgs/`;
export const imgBankUrl = `${url}assets/bank/`;
/* export const imgPjgUrl = `${url}assets/imgs/`;
export const imgProgUrl = `${url}assets/imgs/`; */
// export const gfaccountUrl = `${url}user/`;
