import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const WelcomeRow = () => {
  let navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    var user = localStorage.getItem("fullname");
    var email = localStorage.getItem("email");
    console.log("user: ", user);
    setAuth(user);
    setEmail(email);
    setUserId(id);

    if (user === null) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="flex mb-6 items-center">
      <div className="w-1/2 grow">
        <h1 className="text-primary text-xl">
          Welcome, <b>{auth}</b>
        </h1>
      </div>
      <div className="">
        <div className="bg-gray-200 flex items-center rounded-md overflow-hidden">
          <img className="h-8" src={require("../images/Avatar.png")} alt="" />
          <span className="px-3">{auth}</span>
        </div>
      </div>
    </div>
  );
};

export default WelcomeRow;
