import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { menuUrl } from "../../utils/baseUrl";
import { useForm } from "react-hook-form";

import axios from "axios";
import CustomModal from "../CustomModal";

const ViewMenu = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  /*   `name` varchar(200) NOT NULL,
  `url` varchar(200) NOT NULL,
  `menuId` int(11) NOT NULL DEFAULT 0,
  `sectionId` varchar(100) NOT NULL */
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [datas, setDatas] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [nameEdit, setNameEdit] = useState("");

  function readData() {
    axios.get(`${menuUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
    });
  }

  useEffect(() => {
    readData();
  }, []);

  const editData = (item) => {
    setIdEdit(item?.id);
    setNameEdit(item?.name);
    setShowModal(!false);
  };
  /*   const handleEditSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`${menuUrl}update.php`, {
        id: idEdit,
        name: nameEdit,
      })
      .then(function (response) {
        console.log(response.data);
        readData();
      });
    notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
    setIdEdit("");
    setNameEdit("");
    setShowModal(false);
  }; */

  const handleMenuSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    //partnerprofile id,pnId,positions,address,phone,photo,created_at
    axios
      .post(`${menuUrl}update.php`, {
        idEdit,
        nameEdit,
      })
      .then(function (response) {
        console.log(response.data);

        readData();
        setIdEdit("");
        setNameEdit("");
        notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  const addSubMenu = () => {
    // setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowAddModal(!false);
  };
  const handleOnSubmit = (data) => {
    axios.post(`${menuUrl}add.php`, {}).then(function (response) {
      console.log(response.data);

      reset();
      notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
    });
    //-------------else
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>รายการเมนู</th>
          </tr>
        </thead>
        <tbody>
          {datas.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="mb-4">
                  <h3>
                    <strong>เมนู</strong> {item?.id}#{item?.name}
                  </h3>
                </div>

                <div className="flex justify-end gap-1">
                  {/*                   <button
                    onClick={() => addSubMenu(item?.id)}
                    className="bg-sky-500 hover:bg-sky-700 text-white text-center py-1 px-4 rounded"
                  >
                    เพิ่มหัวข้อย่อย
                  </button> */}
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/*       <CustomModal visible={showAddModal}>
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อเมนูย่อย"
                name="name"
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนชื่อเมนูย่อย
                </div>
              )}
            </div>

            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowAddModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal> */}

      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleMenuSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 undefined">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  // name="idEdit"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 undefined">
                เมนู
              </label>
              <input
                type="text"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                value={nameEdit}
                onChange={(e) => setNameEdit(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default ViewMenu;
