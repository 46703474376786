import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userUrl } from "../../utils/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
    },
  });
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const [isEmail, setIsEmail] = useState(false);

  useEffect(() => {}, []);

  const handleOnSubmit = (data) => {
    axios
      .post(`${userUrl}checkemail.php`, {
        pid: userId,
        email: data.email,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsEmail(true);
          setUser(response.data);
        } else {
          axios
            .post(`${userUrl}add.php`, {
              firstname: data.firstname,
              lastname: data.lastname,
              phone: data.phone,
              email: data.email,
              password: data.password,
            })
            .then(function (response) {
              console.log(response.data);
              setIsEmail(false);
              reset();
              notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
            });
          //-------------else
        }
      });

    //-----------
  };

  const notify = (msg) => toast(msg);
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <div className="">
      {/* --------  */}
      <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อจริง"
                name="firstname"
                {...register("firstname", {
                  required: true,
                  // maxLength: 10,
                })}
              />
              {errors.firstname && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนชื่อจริง
                </div>
              )}
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="นามสกุลจริง"
                name="lastname"
                {...register("lastname", {
                  required: true,
                  // maxLength: 10,
                })}
              />
              {errors.lastname && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนสกุลจริง
                </div>
              )}
            </div>
          </div>
          <div className="mb-2">
            <input
              type="tel"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="โทรศัพท์"
              {...register("phone", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.phone && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนโทรศัพท์
              </div>
            )}
          </div>
          <div className="mb-2">
            <input
              type="email"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="อีเมล์"
              {...register("email", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.email && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนอีเมล์
              </div>
            )}
            {isEmail && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                อีเมล์นี้มีผู้ใช้งานแล้ว
              </div>
            )}
          </div>
          <div className="mb-2">
            <input
              type="password"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="รหัสผ่าน"
              {...register("password", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.password && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนรหัสผ่าน
              </div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-3 text-center bg-blue-500 border rounded-lg text-white  hover:bg-blue-600 active:bg-blue-700 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
            >
              บันทึก
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddUser;
