import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { bankUrl } from "../utils/baseUrl";
import { proUrl, imgProUrl } from "../utils/baseUrl";
import { catUrl } from "../utils/baseUrl";

import axios from "axios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Allalbum = () => {
  const [catData, setCatData] = useState([]);
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [projects, setProjects] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [productName, setProductName] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [price, setPrice] = useState("");
  /*   formData.append("productName", data.productName);
  formData.append("productDetail", data.productDetail);
  formData.append("price", data.price); */
  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }
  function readpj() {
    axios.get(`${proUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }

  useEffect(() => {
    readcat();
    readpj();
  }, []);
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>ชื่อสินค้า/รายละเอียดสินค้า</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.picture !== "" ? (
                    <img
                      src={`${imgProUrl}${item.picture}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <h3>
                  {item.id}#{item.productName}
                </h3>
                <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: item.productDetail }}
                ></p>
                <p>ราคา: {item.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <div className="flex justify-end gap-1 mt-2">
                  <Link
                    to={`/albumdetail/${item.id}`}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    รายละเอียดสินค้า
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Allalbum;
