import React from "react";

const CustomModal = ({ children, visible }) => {
  return (
    <>
      {visible ? (
        <div className="fixed inset-0 p-4 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          {children}
        </div>
      ) : null}
    </>
  );
};

export default CustomModal;
