import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { useForm } from "react-hook-form";

import { subpjUrl, pjUrl, catUrl, imgPjUrl } from "../utils/baseUrl";

import axios from "axios";
import CustomModal from "../components/CustomModal";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};
const SubProject = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "0",
      projectName: "",
      location: "",
      detail: "",
    },
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [catData, setCatData] = useState([]);
  const [value, setValue] = useState("");
  const [projects, setProjects] = useState([]);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    console.log("My user ID: ", id);
    setUserId(id);
  }, []);
  //formData.append("userId", userId);
  //กำลังดำเนินการลบข้อมูล
  //กำลังดำเนินการบันทึกข้อมูล
  //$userId = $_POST["userId"];

  function readcat() {
    axios.get(`${catUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }

  function readpj(pjid) {
    axios.get(`${subpjUrl}read.php/${pjid}`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }

  useEffect(() => {
    readcat();
    readpj(id);
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("projectId", id);
    formData.append("projectName", data.projectName);
    formData.append("location", data.location);
    formData.append("detail", value);
    formData.append("userId", userId);
    setLoading(true);

    axios.post(`${subpjUrl}add.php`, formData).then(function (response) {
      console.log(response.data);
      readpj(id);
      reset();
      setValue("");
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  const deleteData = (pjid) => {
    axios.delete(`${subpjUrl}delete.php/${pjid}`).then(function (response) {
      readpj(id);
      notifyError("ลบข้อมูลเรียบร้อยแล้ว");
    }); //max-w-[1640px]
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const addLinkYoutube = () => {
    // setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowModal(!false);
  };
  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">
        รายละเอียดโครงการ {id}
      </h1>
      <table>
        <thead>
          <tr>
            {/* <th>รูปภาพ</th> addLinkYoutube()*/}
            <th>
              <button
                onClick={() => addLinkYoutube()}
                className="bg-blue-500 text-sm hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
              >
                เพิ่มรายละเอียดโครงการ
              </button>
            </th>
            {/* <th>ที่ตั้งโครงการ</th> */}
            {/* <th>เริ่ม/สิ้นสุดโครงการ งบโครงการ</th> */}
            {/* <th>งบโครงการ</th> */}
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {projects?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.picture !== "" ? (
                    <img
                      src={`${imgPjUrl}${item.picture}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                  <br />
                </div>
                <div className="my-4">
                  <h3>{item.projectName}</h3>
                  <br />
                  <strong>ชื่อเมนูย่อย</strong>
                  <h3>{item.location}</h3>
                  <br />
                  {/*              <p
                    className="mb-4 text-sm"
                    dangerouslySetInnerHTML={{ __html: item.location }}
                  ></p> */}
                  <p
                    className="mb-4 text-sm"
                    dangerouslySetInnerHTML={{ __html: item.detail }}
                  ></p>
                </div>

                <div className="flex flex-col justify-end gap-1 flex-1 mt-2 md:flex-row">
                  <button
                    onClick={() => deleteData(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* -------------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              เพิ่มรายละเอียดโครงการ
            </h1>
          </div>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            {/*             <div className="mb-2">
              <select
                {...register("categoryId", {
                  required: true,
                })}
                className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              >
                <option value=""></option>
                {catData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.categoryName}
                  </option>
                ))}
              </select>
              {errors.categoryId && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ระบุประเภท.
                </div>
              )}
            </div> */}

            {/*       categoryId: "",
      projectName: "",
      location: "",
      beginDate: "",
      endDate: "",
      total: "0", */}

            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อละเอียดโครงการ"
                name="projectName"
                {...register("projectName", {
                  required: true,
                  // maxLength: 10,
                })}
              />
              {errors.projectName && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนละเอียดโครงการ
                </div>
              )}
            </div>
            <div className="mb-2">
              <input
                type="text"
                className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="ชื่อเมนูย่อย*********"
                name="location"
                {...register("location", {
                  required: true,
                  // maxLength: 10,
                })}
              />
              {errors.location && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  ป้อนชื่อเมนูย่อย
                </div>
              )}
            </div>
            <div className="mb-2 h-[300px]">
              <ReactQuill
                modules={modules}
                theme="snow"
                value={value}
                onChange={setValue}
                className="h-4/5"
              />
            </div>
            <div className="my-2">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  /*                   name="selectedFile"
                value={selectedFile} */
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                disabled={loading}
                type="submit"
                className="min-w-[120px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 border border-blue-700 rounded"
              >
                {loading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default SubProject;
