import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { donateUrl } from "../utils/baseUrl";
import { orderUrl, statusUrl } from "../utils/baseUrl";
import { pjUrl, imgPjUrl } from "../utils/baseUrl";
import CustomModal from "../components/CustomModal";
import axios from "axios";
import WelcomeRow from "../components/WelcomeRow";
import DashboardBoxes from "../components/DashboardBoxes";
import TopSellingProducts from "../components/TopSellingProducts";
import { FaShoppingBag, FaUserAlt, FaShoppingCart } from "react-icons/fa";

const Home = () => {
  const [orders, setOrders] = useState([]);
  const [orderToday, setOrderToday] = useState([]);
  const [projects, setProjects] = useState([]);
  const [products, setProducts] = useState([]);
  const [donates, setDonate] = useState([]);
  function readpj() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }
  function readorder() {
    axios.get(`${orderUrl}readorders.php`).then(function (response) {
      console.log(response.data);
      setOrders(response.data);
    });
  }
  function readordertoday() {
    axios.get(`${orderUrl}ordertoday.php`).then(function (response) {
      console.log(response.data);
      setOrderToday(response.data);
    });
  }
  /*   function readproducttoday() {
    axios.get(`${orderUrl}producttoday.php`).then(function (response) {
      console.log(response.data);
      setProducts(response.data);
    });
  } */
  function readdonate() {
    axios.get(`${donateUrl}read.php`).then(function (response) {
      console.log("Donate: ", response.data);
      setDonate(response.data);
    });
  }
  useEffect(() => {
    readorder();
    readordertoday();
    // readproducttoday();
    readpj();
    readdonate();
  }, []);
  //Object.keys(myEmptyObj).length === 0 && myEmptyObj.constructor === Object
  //Object.keys(donates).length === 0 && donates.constructor === Object
  //Object.keys(donates).length !== 0 && donates.constructor !== Object
  const totalDonate = donates.reduce((accumulator, currentItem) => {
    return Number(accumulator) + Number(currentItem.total);
  }, 0);

  const totalOrder = orders.reduce((accumulator, currentItem) => {
    return Number(accumulator) + Number(currentItem.total);
  }, 0);
  const totalOrderToday = orderToday.reduce((accumulator, currentItem) => {
    return Number(accumulator) + Number(currentItem.total);
  }, 0);
  /*   console.log("------------");
  console.log(products);
  console.log("------------"); */
  console.log(totalDonate);
  return (
    <>
      <WelcomeRow />
      <div className="md:flex gap-10">
        <div className="md:w-8/12 grow">
          {/* -----------  */}
          <div className="flex flex-col gap-5 md:flex-row mb-8">
            {/*             <div className="bg-blue-200 grow w-full flex items-center gap-2 text-primary p-5 rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                />
              </svg>
              <div>
                <h2 className="font-bold text-2xl leading-4">
                  {totalOrderToday?.toLocaleString(2)} บาท
                </h2>
                <h3 className="text-xs">ยอดขายสินค้าประจำวัน</h3>
              </div>
            </div> */}
            {/* ------  */}
            <div className="bg-blue-200 grow w-full flex items-center gap-2 text-primary p-5 rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                />
              </svg>
              <div>
                <h2 className="font-bold text-2xl leading-4">
                  {/* {totalDonate?.toLocaleString(2)} บาทxx */}
                  {isNaN(totalDonate)
                    ? "0"
                    : totalDonate?.toLocaleString(2)}{" "}
                  บาท
                </h2>
                <h3 className="text-xs">ยอดรวมบริจาค</h3>
              </div>
            </div>
            {/* ------  */}
            <div className="bg-red-200 grow w-full flex items-center gap-2 text-primary p-5 rounded-tr-3xl rounded-bl-3xl rounded-tl-md rounded-br-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                />
              </svg>
              <div>
                <h2 className="font-bold text-2xl leading-4">
                  {/* {Number(totalOrder).toFixed(2)} บาท */}
                  {/* {totalOrder?.toLocaleString(2)} บาท */}
                  {isNaN(totalOrder) ? "0" : totalOrder?.toLocaleString(2)} บาท
                </h2>
                <h3 className="text-xs">ยอดขายสินค้า</h3>
              </div>
            </div>
          </div>
          {/* -----------  */}
          <div>
            <div className="w-full col-span-1 relative lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white overflow-scroll">
              <h1 className="font-bold">รายการใบสั่งซื้อสินค้าล่าสุด</h1>
              <ul>
                {orders.map((order, id) => (
                  <li
                    key={id}
                    className="bg-gray-50 hover:bg-gray-100 rounded-lg my-3 p-2 flex items-center cursor-pointer"
                  >
                    <div className="bg-purple-100 rounded-lg p-3">
                      <FaShoppingBag className="text-purple-800" />
                    </div>
                    <div className="pl-4">
                      <p className="text-gray-800 font-bold">
                        {order?.total?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        บาท
                      </p>
                      <p className="text-gray-400 text-sm">
                        {order?.firstname} {order?.lastname}
                      </p>
                      <p className="text-gray-400 text-sm">
                        {order?.created_at}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="md:w-4/12 grow mt-4">
          {/* ---------  */}
          {/*           <div className="w-full col-span-1 relative lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white overflow-scroll">
            <h1 className="font-bold">รายการสินค้าขายดี</h1>
            <ul>
              {products.map((product, id) => (
                <li
                  key={id}
                  className="bg-gray-50 hover:bg-gray-100 rounded-lg my-3 p-2 flex items-center cursor-pointer"
                >
                  <div className="bg-purple-100 rounded-lg p-3">
                    <FaShoppingCart className="text-purple-800" />
                  </div>
                  <div className="pl-4">
                    <p className="text-gray-800 font-bold">
                      {product?.price} บาท
                    </p>
                    <p className="text-gray-400 text-sm">
                      {product?.productName}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div> */}
          <div className="w-full col-span-1 relative lg:h-[70vh] h-[50vh] m-auto p-4 border rounded-lg bg-white overflow-scroll">
            <h1 className="font-bold">รายชื่อผู้บริจาคล่าสุด</h1>
            <ul>
              {donates.length > 1 ? (
                <>
                  {donates.map((donate, id) => (
                    <li
                      key={id}
                      className="bg-gray-50 hover:bg-gray-100 rounded-lg my-3 p-2 flex items-center cursor-pointer"
                    >
                      <div className="bg-purple-100 rounded-lg p-3">
                        <FaShoppingCart className="text-purple-800" />
                      </div>
                      <div className="pl-4">
                        <p className="text-gray-800 font-bold">
                          {donate?.total?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          บาท
                        </p>
                        <p className="text-gray-400 text-sm">
                          {donate?.fullname}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {donate?.created_at}
                        </p>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  <h1 className="text-red-500 mt-4 pl-4">ไม่มีรายการบริจาค</h1>
                </>
              )}
            </ul>
          </div>
          {/* ---------  */}
        </div>
      </div>
    </>
  );
};

export default Home;
