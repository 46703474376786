import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { bankUrl } from "../utils/baseUrl";
import { infoslideUrl, infoslideFileUrl } from "../../utils/baseUrl";

import axios from "axios";
import CustomModal from "../CustomModal";

const ViewInfoslide = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSectedFile] = useState(null);
  const [datas, setDatas] = useState([]);
  const [idEdit, setIdEdit] = useState("");
  const [title, setTitle] = useState("");

  function readData() {
    axios.get(`${infoslideUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDatas(response.data);
    });
  }

  useEffect(() => {
    readData();
  }, []);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const deleteData = (id) => {
    axios
      .delete(`${infoslideUrl}delete.php/${id}/delete`)
      .then(function (response) {
        readData();
        notifyError("ลบข้อมูลเรียบร้อยแล้ว");
      }); //max-w-[1640px]
  };
  const editData = (item) => {
    setIdEdit(item?.id);
    setTitle(item?.title);
    setShowModal(!false);
  };
  /*   const handleEditSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`${infoslideUrl}update.php`, {
        id: idEdit,
        title,
      })
      .then(function (response) {
        console.log(response.data);
        readData();
      });
    notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
    setIdEdit("");
    setTitle("");
    setShowModal(false);
  }; */

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("title", title);
    formData.append("id", idEdit);
    setLoading(true);

    //partnerprofile id,pnId,positions,address,phone,photo,created_at
    axios.post(`${infoslideUrl}update.php`, formData).then(function (response) {
      console.log(response.data);

      readData();
      notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
      setLoading(false); // Stop loading
    });
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>รายการประกาศข่าว</th>
          </tr>
        </thead>
        <tbody>
          {datas.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.photo != "" ? (
                    <img
                      src={`${infoslideFileUrl}${item.photo}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>{item.title}</div>

                <div className="flex justify-end gap-1">
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                  <button
                    onClick={() => deleteData(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 undefined">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  // name="idEdit"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
                />
              </div>
            </div>
            {/* const { idEdit, incomeEName, amountEPercent, incomeEAmount } = inputEdit; */}
            <div className="mb-3">
              <input
                type="text"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100 "
                placeholder="เนื้อหาข่าว"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="my-3">
              <label className="block text-sm font-medium text-gray-700 ml-2">
                รูปภาพ
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="file"
                  onChange={handleOnChange}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                {loading ? <>กรุณารอ..</> : <>บันทึก99</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default ViewInfoslide;
