import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
// import { bankUrl } from "../utils/baseUrl";
import { orderUrl } from "../utils/baseUrl";

import axios from "axios";
// import CustomModal from "../CustomModal";
// import { useNavigate } from "react-router-dom";

const OrderDetail = () => {
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState([]);
  let amount = 0;
  let subtotal = 0;
  let total = 0;
  function readorder() {
    axios.get(`${orderUrl}readorderdetail.php`).then(function (response) {
      console.log(response.data);
      setOrderDetail(response.data);
    });
  }
  useEffect(() => {
    readorder();
  }, []);
  const products = orderDetail?.filter((item) => {
    return item.orderId == id; // return item.id === parseInt(id);
  });

  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">
        รายการสินค้าใบสั่งซื้อเลขที่#{id}
      </h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          {products.map((item, index) => {
            amount = Number(amount) + Number(item.qty);
            subtotal = Number(subtotal) + Number(item.price);
            total = total + subtotal;
            return (
              <tr key={index}>
                <td>
                  <div>
                    {item.id}#{item.orderId}
                    <br />
                    <strong>ชื่อสินค้า</strong> {item.productName}
                    <br />
                    <strong>ราคาสินค้า</strong> {item.price} บาท
                    <br />
                    <strong>จำนวน </strong> {item.qty}
                    <br />
                    <strong>รวมเงิน </strong>{" "}
                    {Number(item.price * item.qty).toFixed(2)} บาท
                    <br />
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <td>
              <strong>จำนวนรวม</strong> {amount}
              <br />
              <strong>รวมราคาทั้งหมด </strong>
              {Number(total).toFixed(2)} บาท
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default OrderDetail;
