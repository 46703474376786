import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// import { bankUrl } from "../utils/baseUrl";
import { pjyoutubeUrl } from "../utils/baseUrl";

import axios from "axios";
import CustomModal from "../components/CustomModal";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player/youtube";

const Youtube = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [youtubes, setYoutubes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  //pjyoutube id,userId,projectId,title,url
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  function readYoutube(id) {
    axios.get(`${pjyoutubeUrl}read.php/${id}`).then(function (response) {
      console.log(response.data);
      setYoutubes(response.data);
    });
  }
  useEffect(() => {
    var user = localStorage.getItem("id");
    console.log("user: ", user);
    setUserId(user);
    readYoutube(id);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    /*     $projectId   = $data->projectId;
    $title   = $data->title;
    $url   = $data->url;
    $userId= $data->userId; */
    axios
      .post(`${pjyoutubeUrl}add.php`, {
        projectId: id,
        userId: userId,
        title,
        url,
      })
      .then(function (response) {
        console.log(response.data);
        readYoutube(id);
        setTitle("");
        setUrl("");
        notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
        setLoading(false); // Stop loading
        setShowModal(false);
      });
  };

  const deleteIncome = (youid) => {
    axios
      .delete(`${pjyoutubeUrl}delete.php/${youid}/delete`)
      .then(function (response) {
        readYoutube(id);
        notifyError("กำลังดำเนินการลบข้อมูล");
      }); //max-w-[1640px]
  };

  const addLinkYoutube = () => {
    // setIdEdit(item?.id);
    /*    setSoundname(item?.soundname); */
    setShowModal(!false);
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">
        ความคืบหน้าโครงการ
      </h1>
      <table>
        <thead>
          <tr>
            {/* <th>รูปภาพ</th> */}
            <th>
              <button
                onClick={() => addLinkYoutube()}
                className="bg-blue-500 text-sm hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
              >
                เพิ่มความคืบหน้าโครงการ
              </button>
            </th>
            {/* <th>ที่ตั้งโครงการ</th> */}
            {/* <th>เริ่ม/สิ้นสุดโครงการ งบโครงการ</th> */}
            {/* <th>งบโครงการ</th> */}
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {youtubes?.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item?.url !== "" ? (
                    // Only loads the YouTube player
                    <ReactPlayer
                      url={item?.url}
                      className="max-w-xs h-auto md:max-w-2xl"
                    />
                  ) : (
                    /*                   <img
                    src={`${imgPjUrl}${item.picture}`}
                    className="h-[150px] object-cover w-full my-4 md:w-1/2"
                  /> */
                    ""
                  )}
                </div>
                <div className="mb-4">
                  {/*                   <h3>
                    {" "}
                    {item.id}.{item.title}
                  </h3> */}
                  <p
                    className="mb-4 text-sm"
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                  ></p>
                  <br />
                  <strong>Link</strong> {item?.url}
                  <br />
                  {/*                 <strong>เริ่ม</strong> {item.beginDate}
                <br />
                <strong>สิ้นสุดโครงการ</strong> {item.endDate}
                <br />
                <strong>งบโครงการ</strong>{" "}
                {item.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท */}
                </div>
                {/* pjyoutube id,userId,projectId,title,url */}
                <div className="flex justify-end gap-1 flex-1">
                  <button
                    onClick={() => deleteIncome(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* -------------  */}
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">
              แก้ไขโครงการ
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <input
                type="url"
                className="w-full py-2 border border-slate-200  px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                placeholder="link youtube"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="mb-12 h-[100px]">
              <ReactQuill
                theme="snow"
                className="h-5/6"
                value={title}
                onChange={setTitle}
              />
            </div>

            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                {loading ? <>กรุณารอ..</> : <>บันทึก99</>}
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default Youtube;
