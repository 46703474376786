import React from "react";
import { Link, Outlet, useLocation, redirect } from "react-router-dom";

const Contact = () => {
  const location = useLocation();
  // console.log("pathname", location.pathname);
  const path = location.pathname;
  return (
    <>
      <div className="mb-6 items-center">
        <div className="flex items-center justify-end gap-1  rounded-md overflow-hidden">
          <Link
            to={"addcontact"}
            className="bg-blue-500 text-sm hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
          >
            เพิ่มข้อมูล
          </Link>
          <Link
            to={"viewcontact"}
            className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
          >
            แสดงข้อมูล
          </Link>
        </div>
      </div>
      {/* ----------  */}
      <div className="md:flex gap-10 min-h-screen">
        <div className="grow mt-4">
          <h1 className="mb-4 font-bold">จัดการติดต่อเรา</h1>
          {path === "/contacts" ? (
            <>
              <div className="container mt-4 mx-auto w-full bg-pink-100">
                <div className="grid grid-cols-1">
                  <div className="card m-2 cursor-pointer border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200">
                    <div className="m-3">
                      <h2 className="text-lg mb-2">
                        คำแนะนำ
                        <span className="text-sm text-teal-800 font-mono bg-teal-100 inline rounded-full px-2 align-top float-right animate-pulse"></span>
                      </h2>
                      <p className="font-light font-mono text-sm text-gray-700 hover:text-gray-900 transition-all duration-200">
                        ท่านสามารถเพิ่มข้อมูลใหม่ โดยกดปุ่ม เพิ่มข้อมูล
                        หรือกดปุ่ม แสดงข้อมูล เพื่อทำการลบ แก้ไขข้อมูล
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Contact;
