import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { companyUrl } from "../../utils/baseUrl";

import axios from "axios";

const AddCompany = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: 0,
      companyName: "",
      companyAddress: "",
      companyLocation: "",
      companyPhoto: "",
    },
  });
  const [selectedFile, setSectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    var id = localStorage.getItem("id");
    console.log("My user ID: ", id);
    setUserId(id);
  }, []);
  //formData.append("userId", userId);
  //กำลังดำเนินการลบข้อมูล
  //กำลังดำเนินการบันทึกข้อมูล
  //$userId = $_POST["userId"];

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("companyName", data.companyName);
    formData.append("companyAddress", data.companyAddress);
    formData.append("companyLocation", data.companyLocation);
    formData.append("userId", userId);
    setLoading(true);

    axios.post(`${companyUrl}add.php`, formData).then(function (response) {
      console.log(response.data);

      reset();
      notifySuccess("กำลังดำเนินการบันทึกข้อมูล");
      setLoading(false); // Stop loading
    });
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <div className="">
      {/* --------  */}
      <div className="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="mb-6">
            <input
              type="text"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="ชื่อบริษัท"
              name="companyName"
              {...register("companyName", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.companyName && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนชื่อบริษัท
              </div>
            )}
          </div>
          <div className="mb-6">
            <input
              type="text"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="ที่อยู่บริษัท"
              name="companyAddress"
              {...register("companyAddress", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.companyAddress && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนที่อยู่บริษัท
              </div>
            )}
          </div>
          <div className="mb-6">
            <input
              type="text"
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
              placeholder="รายละเอียดที่ตั้ง"
              name="companyLocation"
              {...register("companyLocation", {
                required: true,
                // maxLength: 10,
              })}
            />
            {errors.companyLocation && (
              <div className="my-3 text-normal text-red-500 text-xs italic">
                ป้อนรายละเอียดที่ตั้ง
              </div>
            )}
          </div>
          <div className="my-4">
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700 ml-2"
            >
              รูปภาพ
            </label>
            <div className="flex flex-col items-start">
              <input
                type="file"
                /*                   name="selectedFile"
                  value={selectedFile} */
                onChange={handleOnChange}
                className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-2 px-3 text-center bg-blue-500 border rounded-lg text-white  hover:bg-blue-600 active:bg-blue-700 hover:text-gray-100 dark:text-gray-200 dark:bg-violet-700"
            >
              {loading ? <>กรุณารอ..</> : <>บันทึก99</>}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddCompany;
