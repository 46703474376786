import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { bankUrl } from "../utils/baseUrl";
import { pjUrl, imgPjUrl } from "../utils/baseUrl";

import axios from "axios";
// import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";

const AllDonate = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  function readpj() {
    axios.get(`${pjUrl}read.php`).then(function (response) {
      console.log(response.data);
      setProjects(response.data);
    });
  }
  useEffect(() => {
    readpj();
  }, []);
  return (
    <>
      <h1 className="text-primary text-xl font-bold mb-4">โครงการบริจาค</h1>
      <table>
        <thead>
          <tr>
            {/* <th>รูปภาพ</th> */}
            <th>ชื่อโครงการ</th>
            {/* <th>ที่ตั้งโครงการ</th> */}
            {/* <th>เริ่ม/สิ้นสุดโครงการ งบโครงการ</th> */}
            {/* <th>งบโครงการ</th> */}
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {projects.map((item, index) => (
            <tr key={index}>
              <td>
                <div>
                  {item.picture !== "" ? (
                    <img
                      src={`${imgPjUrl}${item.picture}`}
                      className="h-[150px] object-cover w-full my-4 md:w-1/2"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4">
                  <h3>
                    {" "}
                    {item.id}.{item.projectName}
                  </h3>
                  <br />
                  <strong>ที่ตั้งโครงการ</strong> {item.location}
                  <br />
                  <strong>เริ่ม</strong> {item.beginDate}
                  <br />
                  <strong>สิ้นสุดโครงการ</strong> {item.endDate}
                  <br />
                  <strong>งบโครงการ</strong>{" "}
                  {item.total.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} บาท
                </div>
                <div className="flex justify-end gap-1 flex-1">
                  <Link
                    to={`/subproject/${item.id}`}
                    className="bg-violet-500 text-sm  hover:bg-violet-700 text-white text-center py-1 px-4 rounded"
                  >
                    กำหนดรายการย่อย
                  </Link>
                  <Link
                    to={`/projectvedio/${item.id}`}
                    className="bg-sky-500 text-sm  hover:bg-sky-700 text-white text-center py-1 px-4 rounded"
                  >
                    ความคืบหน้าโครงการ วีดิโอ
                  </Link>
                  <Link
                    to={`/youtubedetail/${item.id}`}
                    className="bg-green-500 text-sm  hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    ความคืบหน้าโครงการ Youtube
                  </Link>
                  <Link
                    to={`/donatedetail/${item.id}`}
                    className="bg-blue-500 text-sm  hover:bg-blue-700 text-white text-center py-1 px-4 rounded"
                  >
                    รายชื่อผู้บริจาค
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AllDonate;
