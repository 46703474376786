import React, { useState, useEffect } from "react";
import { userUrl } from "../../utils/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CustomModal from "../CustomModal";

// import { useParams } from "react-router-dom";

const ViewUser = () => {
  const [catData, setCatData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idEdit, setIdEdit] = useState("");
  const [firstnameEdit, setFirstnameEdit] = useState("");
  const [lastnameEdit, setLastnameEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [passwordEdit, setPasswordEdit] = useState("");
  function readcat() {
    axios.get(`${userUrl}read.php`).then(function (response) {
      console.log(response.data);
      setCatData(response.data);
    });
  }

  useEffect(() => {
    readcat();
  }, []);

  /*   const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  }; */

  const deleteIncome = (id) => {
    axios.delete(`${userUrl}delete.php/${id}/delete`).then(function (response) {
      readcat();
      notifyError("ลบข้อมูลเรียบร้อยแล้ว");
    }); //max-w-[1640px]
  };
  const editData = (item) => {
    setIdEdit(item?.id);
    setFirstnameEdit(item?.firstname);
    setLastnameEdit(item?.lastname);
    setPhoneEdit(item?.phone);
    setEmailEdit(item?.email);
    setPasswordEdit(item?.password);
    setShowModal(!false);
  };
  /*   const handleEditSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`${companyUrl}update.php`, {
        id: idEdit,
        categoryName,
      })
      .then(function (response) {
        console.log(response.data);
        readcat();
        navigate("/");
      });
    notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
    setIdEdit("");
    setCategoryName("");
    setShowModal(false);
  }; */

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${userUrl}update.php`, {
        idEdit,
        firstnameEdit,
        lastnameEdit,
        emailEdit,
        phoneEdit,
        passwordEdit,
      })
      .then(function (response) {
        console.log(response.data);
        readcat();
        notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setShowModal(false);
      });
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>ข้อมูลผู้ใช้งาน</th>
          </tr>
        </thead>
        <tbody>
          {catData.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="mb-4">
                  <h3>
                    <strong>ชื่อ-สกุล</strong> {item.id}#{item.firstname}/
                    {item.lastname}
                  </h3>
                  <h3>
                    <strong>โทรศัพท์</strong> {item.phone}
                  </h3>
                  <h3>
                    <strong>อีเมล์</strong> {item.email}({item.password})
                  </h3>
                </div>

                <div className="flex justify-end gap-1">
                  <button
                    onClick={() => editData(item)}
                    className="bg-green-500 hover:bg-green-700 text-white text-center py-1 px-4 rounded"
                  >
                    แก้ไข
                  </button>
                  <button
                    onClick={() => deleteIncome(item.id)}
                    className="bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                  >
                    ลบ
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CustomModal visible={showModal}>
        {/* here you can add your other components (JSX)*/}
        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <div className="mb-4">
            <h1 className="font-bold underline decoration-gray-400">แก้ไข</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="block text-sm font-medium text-gray-700 undefined">
                รหัส
              </label>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  // name="idEdit"
                  value={idEdit}
                  onChange={(e) => setIdEdit(e.target.value)}
                  className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-6">
                <input
                  type="text"
                  className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  value={firstnameEdit}
                  onChange={(e) => setFirstnameEdit(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <input
                  type="text"
                  className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  value={lastnameEdit}
                  onChange={(e) => setLastnameEdit(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-6">
              <input
                type="tel"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                value={phoneEdit}
                onChange={(e) => setPhoneEdit(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <input
                type="email"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                value={emailEdit}
                onChange={(e) => setEmailEdit(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <input
                type="password"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                value={passwordEdit}
                onChange={(e) => setPasswordEdit(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                //   onClick={handleSubmit}
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
              >
                บันทึก
              </button>
              <button
                className="ml-1 inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex items-center gap-1"
                onClick={() => setShowModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </form>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default ViewUser;
